var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('validation-observer',{ref:"observer"},[_c('form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Asset Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Asset Number","dense":"","required":"","outlined":""},model:{value:(_vm.radios.asset_number),callback:function ($$v) {_vm.$set(_vm.radios, "asset_number", $$v)},expression:"radios.asset_number"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Serial Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Serial Number","dense":"","required":"","outlined":""},model:{value:(_vm.radios.serial_number),callback:function ($$v) {_vm.$set(_vm.radios, "serial_number", $$v)},expression:"radios.serial_number"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Type","dense":"","required":"","outlined":""},model:{value:(_vm.radios.type),callback:function ($$v) {_vm.$set(_vm.radios, "type", $$v)},expression:"radios.type"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.radio_status,"error-messages":errors,"item-text":"name","item-value":"name","menu-props":"auto","label":"Status","outlined":"","required":"","dense":"","prepend-inner-icon":_vm.icons.mdiHeartPulse},model:{value:(_vm.radios.status),callback:function ($$v) {_vm.$set(_vm.radios, "status", $$v)},expression:"radios.status"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"License","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"License","dense":"","required":"","outlined":""},model:{value:(_vm.radios.license),callback:function ($$v) {_vm.$set(_vm.radios, "license", $$v)},expression:"radios.license"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Expiry","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-picker-dialog',{attrs:{"minimum_date":"1950-01-01","error-messages":errors,"label":"Expiry"},on:{"forceDateUpdate":_vm.forceDateUpdate},model:{value:(_vm.radios.expiry),callback:function ($$v) {_vm.$set(_vm.radios, "expiry", $$v)},expression:"radios.expiry"}})]}}])})],1)],1),_c('v-btn',{staticClass:"ma-10 pa-5",attrs:{"type":"submit","color":"primary"}},[_vm._v(" Save ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }